import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: '',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image primary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-01.svg')}
                        alt="Features tile icon 01"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-left" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Sewages Treatment Plants
                    </h4>
                    <p className="m-0 text-sm reveal-from-left" data-reveal-delay="400" data-reveal-container=".tiles-item">
                      Our electrolytic method offers comparative advantages in relation to the biological treatment technologies.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image secondary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-02.svg')}
                        alt="Features tile icon 02"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-bottom" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Leandfill Leachate
                    </h4>
                    <p className="m-0 text-sm reveal-from-bottom" data-reveal-delay="400" data-reveal-container=".tiles-item">
                      The treatment of landfill leachate requires advanced treatment methods, such as our Advanced Oxidation Process of Electrolysis.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image primary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-03.svg')}
                        alt="Features tile icon 03"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Drinking Water
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-delay="400" data-reveal-container=".tiles-item">
                      The treatment with electrode based on the production of powerful oxidants ensures the highest possible physic- and chemical quality of potable water.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image secondary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-04.svg')}
                        alt="Features tile icon 04"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-left" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Swimming Pools
                    </h4>
                    <p className="m-0 text-sm reveal-from-left" data-reveal-delay="400" data-reveal-container=".tiles-item">
                      Our method is able to kill 100% of all pathogens, including Cryptosporidium.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image primary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-05.svg')}
                        alt="Features tile icon 05"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-bottom" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Olive Oil Mills
                    </h4>
                    <p className="m-0 text-sm reveal-from-bottom" data-reveal-delay="400" data-reveal-container=".tiles-item">
                      Our method solves the environmental and social problem with the best, environmentally acceptable and techno-economically efficient manner, promoting green growth.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image secondary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-06.svg')}
                        alt="Features tile icon 06"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Slaughterhouses
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-delay="400" data-reveal-container=".tiles-item">
                      The treated water outflow is totally sterilized, colorless and odorless, free of animal antibiotics and medicine, completely harmless for the environment.
                    </p>
                  </div>
                </div>
              </div>      

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;