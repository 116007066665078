import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';
import Roadmap from '../components/sections/Roadmap';
import GenericSection from '../components/sections/GenericSection';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import SectionHeader from '../components/sections/partials/SectionHeader';

class Home extends React.Component {
  render() {

    const genericSection02Header = {
      title: 'FAQ - Lorem ipsum is placeholder text commonly used.'
    }

    return (
      <React.Fragment>
        <HeroFull hasBgColor invertColor className="illustration-section-01" />
        {/* <FeaturesTiles />
        <FeaturesSplit topDivider invertMobile imageFill />
        <Roadmap topDivider />
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <Accordion>
              <AccordionItem title="How does Ferekarpos protect the environment?" active>
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="What does Ferekarpos attempt to do about oil waste treatment plants going out of business?">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <Cta hasBgColor invertColor split /> */}
      </React.Fragment>
    );
  }
}

export default Home;